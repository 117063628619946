var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-route-opt",
      "size": "xl",
      "cancel-variant": "secondary",
      "ok-only": "",
      "ok-title": "Close",
      "centered": "",
      "title": "x Paket Pengiriman "
    },
    on: {
      "shown": _vm.modalShown,
      "hidden": function hidden($event) {
        return _vm.$emit('clear');
      }
    }
  }, [_c('l-map', {
    ref: "map",
    staticStyle: {
      "height": "500px"
    },
    attrs: {
      "zoom": _vm.zoom,
      "center": _vm.center
    },
    on: {
      "ready": _vm.initializeRouting
    }
  }, [_c('l-tile-layer', {
    attrs: {
      "url": _vm.url
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }