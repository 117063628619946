var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-actions', {
    attrs: {
      "action-collapse": "",
      "title": "Daftar Tugas Pengiriman"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.add($event);
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "PlusIcon"
    }
  }), _vm._v(" Tambah ")], 1), _c('b-modal', {
    attrs: {
      "size": "lg",
      "id": "modal-tambah",
      "cancel-variant": "secondary",
      "ok-title": "Tambah",
      "cancel-title": "Batal",
      "centered": "",
      "title": "Form Tambah",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": _vm.submit
          }
        }, [_vm._v(" Tambah ")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": _vm.resetForm
          }
        }, [_vm._v(" Batal ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showModalTambah,
      callback: function callback($$v) {
        _vm.showModalTambah = $$v;
      },
      expression: "showModalTambah"
    }
  }, [_c('b-form', [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "driver_id"
    }
  }, [_vm._v("Nama Pengirim")]), _c('v-select', {
    attrs: {
      "options": _vm.driverOptions,
      "reduce": function reduce(option) {
        return option.value;
      },
      "label": "text"
    },
    scopedSlots: _vm._u([{
      key: "search",
      fn: function fn(_ref) {
        var attributes = _ref.attributes,
          events = _ref.events;
        return [_c('input', _vm._g(_vm._b({
          staticClass: "vs__search",
          attrs: {
            "required": !_vm.driver_id
          }
        }, 'input', attributes, false), events))];
      }
    }]),
    model: {
      value: _vm.driver_id,
      callback: function callback($$v) {
        _vm.driver_id = $$v;
      },
      expression: "driver_id"
    }
  }), _c('label', {
    attrs: {
      "for": "shipping_order_ids"
    }
  }, [_vm._v("Nomor Resi")]), _c('v-select', {
    attrs: {
      "multiple": "",
      "options": _vm.shippingOrderOptions,
      "reduce": function reduce(option) {
        return option.value;
      },
      "label": "text"
    },
    scopedSlots: _vm._u([{
      key: "search",
      fn: function fn(_ref2) {
        var attributes = _ref2.attributes,
          events = _ref2.events;
        return [_c('input', _vm._g(_vm._b({
          staticClass: "vs__search",
          attrs: {
            "required": !_vm.shipping_order_ids
          }
        }, 'input', attributes, false), events))];
      }
    }]),
    model: {
      value: _vm.shipping_order_ids,
      callback: function callback($$v) {
        _vm.shipping_order_ids = $$v;
      },
      expression: "shipping_order_ids"
    }
  })], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "size": "lg",
      "id": "modal-ubah",
      "cancel-variant": "secondary",
      "ok-title": "Ubah",
      "cancel-title": "Batal",
      "centered": "",
      "title": "Form Ubah",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "info"
          },
          on: {
            "click": _vm.submit
          }
        }, [_vm._v(" Ubah ")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": _vm.resetForm
          }
        }, [_vm._v(" Batal ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showModalEdit,
      callback: function callback($$v) {
        _vm.showModalEdit = $$v;
      },
      expression: "showModalEdit"
    }
  }, [_c('b-form', [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "driver_id"
    }
  }, [_vm._v("Nama Pengirim")]), _c('v-select', {
    attrs: {
      "options": _vm.driverOptions,
      "reduce": function reduce(option) {
        return option.value;
      },
      "label": "text"
    },
    scopedSlots: _vm._u([{
      key: "search",
      fn: function fn(_ref3) {
        var attributes = _ref3.attributes,
          events = _ref3.events;
        return [_c('input', _vm._g(_vm._b({
          staticClass: "vs__search",
          attrs: {
            "required": !_vm.driver_id
          }
        }, 'input', attributes, false), events))];
      }
    }]),
    model: {
      value: _vm.driver_id,
      callback: function callback($$v) {
        _vm.driver_id = $$v;
      },
      expression: "driver_id"
    }
  }), _c('label', {
    attrs: {
      "for": "shipping_order_ids"
    }
  }, [_vm._v("Nomor Resi")]), _c('v-select', {
    attrs: {
      "multiple": "",
      "options": _vm.shippingOrderOptions,
      "reduce": function reduce(option) {
        return option.value;
      },
      "label": "text"
    },
    scopedSlots: _vm._u([{
      key: "search",
      fn: function fn(_ref4) {
        var attributes = _ref4.attributes,
          events = _ref4.events;
        return [_c('input', _vm._g(_vm._b({
          staticClass: "vs__search",
          attrs: {
            "required": !_vm.shipping_order_ids
          }
        }, 'input', attributes, false), events))];
      }
    }]),
    model: {
      value: _vm.shipping_order_ids,
      callback: function callback($$v) {
        _vm.shipping_order_ids = $$v;
      },
      expression: "shipping_order_ids"
    }
  })], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-route",
      "size": "xl",
      "cancel-variant": "secondary",
      "ok-only": "",
      "ok-title": "Close",
      "centered": "",
      "title": "Log Route Paket Pengiriman",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "info"
          },
          on: {
            "click": function click($event) {
              return _vm.saveGenerateRoute();
            }
          }
        }, [_vm._v(" Simpan Rute ")]), _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "info"
          },
          on: {
            "click": function click($event) {
              return _vm.showOptMap(_vm.detailRoute.best_solution);
            }
          }
        }, [_vm._v(" Lihat Dulu Rute ")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": _vm.resetForm
          }
        }, [_vm._v(" Batal ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showModalOptimizeLog,
      callback: function callback($$v) {
        _vm.showModalOptimizeLog = $$v;
      },
      expression: "showModalOptimizeLog"
    }
  }, [_c('b-table', {
    attrs: {
      "items": _vm.detailRoute.generations,
      "fields": _vm.logFields,
      "striped": "",
      "hover": "",
      "small": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function fn(row) {
        return [_c('strong', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(logs)",
      fn: function fn(row) {
        return [row.item.individuals && row.item.individuals.length > 0 ? _vm._l(row.item.individuals, function (log, index) {
          return _c('div', {
            key: index,
            staticClass: "mb-1"
          }, [_vm._v(" Individu: " + _vm._s(log.id) + " - Urutan Rute : " + _vm._s(log.route_sequence) + " - Nilai jarak: " + _vm._s(log.total_distance) + " ")]);
        }) : [_vm._v(" - ")]];
      }
    }])
  })], 1)], 1), _c('leaflet-basic', {
    ref: "mods",
    attrs: {
      "waypoints": _vm.wpMap
    }
  }), _vm._v(" --> ")], 1), _c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "4",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Sort",
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "sortBySelect"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-75",
    attrs: {
      "id": "sortBySelect",
      "options": _vm.sortOptions
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("-- none --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortBy,
      callback: function callback($$v) {
        _vm.sortBy = $$v;
      },
      expression: "sortBy"
    }
  }), _c('b-form-select', {
    staticClass: "w-25",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortBy
    },
    model: {
      value: _vm.sortDesc,
      callback: function callback($$v) {
        _vm.sortDesc = $$v;
      },
      expression: "sortDesc"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Filter",
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search",
      "placeholder": "Type to Search"
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function click($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "items": _vm.items,
      "fields": _vm.fields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function fn(row) {
        return [_c('strong', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(nomor-resi)",
      fn: function fn(row) {
        return [row.item.shipping_orders && row.item.shipping_orders.length > 0 ? _vm._l(row.item.shipping_orders, function (order, index) {
          return _c('b-badge', {
            key: index,
            staticClass: "mr-1",
            attrs: {
              "variant": "info"
            }
          }, [_vm._v(" " + _vm._s(order.tracking_number) + " ")]);
        }) : [_vm._v(" - ")]];
      }
    }, {
      key: "cell(created_at)",
      fn: function fn(row) {
        return [_c('strong', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(_vm.humanDateTime(row.value)) + " ")])];
      }
    }, {
      key: "cell(status)",
      fn: function fn(row) {
        return [_c('b-badge', {
          staticClass: "mr-1",
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" " + _vm._s(row.value) + " ")])];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(row) {
        return [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Ubah',
            expression: "'Ubah'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-info"
          },
          on: {
            "click": function click($event) {
              return _vm.edit(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Rute',
            expression: "'Rute'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-info"
          },
          on: {
            "click": function click($event) {
              return _vm.getGenerateRoute(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "CompassIcon"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Hapus',
            expression: "'Hapus'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          attrs: {
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function click($event) {
              return _vm.remove(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1)];
      }
    }, {
      key: "row-details",
      fn: function fn(row) {
        return [_c('b-card', [_c('ul', _vm._l(row.item, function (value, key) {
          return _c('li', {
            key: key
          }, [_vm._v(_vm._s(key) + ": " + _vm._s(value))]);
        }), 0)])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": _vm.infoModal.id,
      "title": _vm.infoModal.title,
      "ok-only": ""
    },
    on: {
      "hide": _vm.resetInfoModal
    }
  }, [_c('pre', [_vm._v(_vm._s(_vm.infoModal.content))])]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }