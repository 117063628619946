<template>
  <b-card-actions action-collapse title="Daftar Tugas Pengiriman">
    <!-- ref="refreshCard"
      action-refresh
      @refresh="refreshStop('refreshCard')" -->
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" @click.prevent="add" variant="primary">
          <feather-icon icon="PlusIcon" class="mr-50" />
          Tambah
        </b-button>
        <b-modal size="lg" v-model="showModalTambah" id="modal-tambah" cancel-variant="secondary" ok-title="Tambah"
          cancel-title="Batal" centered title="Form Tambah" no-close-on-backdrop no-close-on-esc>
          <b-form>
            <b-form-group>
              <label for="driver_id">Nama Pengirim</label>
              <v-select v-model="driver_id" :options="driverOptions" :reduce="(option) => option.value" label="text">
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!driver_id" v-bind="attributes" v-on="events" />
                </template>
              </v-select>
              <label for="shipping_order_ids">Nomor Resi</label>
              <v-select v-model="shipping_order_ids" multiple :options="shippingOrderOptions"
                :reduce="(option) => option.value" label="text">
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!shipping_order_ids" v-bind="attributes" v-on="events" />
                </template>
              </v-select>
            </b-form-group>
          </b-form>

          <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center">
              <b-button size="sm" class="mr-1" variant="primary" @click="submit"> Tambah </b-button>
              <b-button size="sm" variant="danger" @click="resetForm"> Batal </b-button>
            </section>
          </template>
        </b-modal>
        <b-modal size="lg" v-model="showModalEdit" id="modal-ubah" cancel-variant="secondary" ok-title="Ubah"
          cancel-title="Batal" centered title="Form Ubah" no-close-on-backdrop no-close-on-esc>
          <b-form>
            <b-form-group>
              <label for="driver_id">Nama Pengirim</label>
              <v-select v-model="driver_id" :options="driverOptions" :reduce="(option) => option.value" label="text">
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!driver_id" v-bind="attributes" v-on="events" />
                </template>
              </v-select>
              <label for="shipping_order_ids">Nomor Resi</label>
              <v-select v-model="shipping_order_ids" multiple :options="shippingOrderOptions"
                :reduce="(option) => option.value" label="text">
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!shipping_order_ids" v-bind="attributes" v-on="events" />
                </template>
              </v-select>
            </b-form-group>
          </b-form>

          <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center">
              <b-button size="sm" class="mr-1" variant="info" @click="submit"> Ubah </b-button>
              <b-button size="sm" variant="danger" @click="resetForm"> Batal </b-button>
            </section>
          </template>
        </b-modal>
        <b-modal v-model="showModalOptimizeLog" id="modal-route" size="xl" cancel-variant="secondary" ok-only ok-title="Close"
          centered title="Log Route Paket Pengiriman" no-close-on-backdrop no-close-on-esc>

          <b-table :items="detailRoute.generations" :fields="logFields" striped hover small>
            <template #cell(no)="row">
              <strong class="text-center">
                {{ row.index + 1 }}
              </strong>
            </template>
            <template #cell(logs)="row">
              <template v-if="row.item.individuals && row.item.individuals.length > 0">
                <div v-for="(log, index) in row.item.individuals" :key="index" class="mb-1">
                  Individu: {{ log.id }} - Urutan Rute : {{ log.route_sequence }} - Nilai jarak: {{ log.total_distance
                  }}
                </div>
              </template>
              <template v-else>
                -
              </template>
            </template>
          </b-table>
          <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center">
              <b-button size="sm" class="mr-1" variant="info" @click="saveGenerateRoute()"> Simpan Rute </b-button>
              <b-button size="sm" class="mr-1" variant="info" @click="showOptMap(detailRoute.best_solution)"> Lihat Dulu Rute </b-button>
              <b-button size="sm" variant="danger" @click="resetForm"> Batal </b-button>
            </section>
          </template>
        </b-modal>
      </b-col>
      <!-- modal leaflet -->
      <leaflet-basic ref="mods" :waypoints="wpMap" /> -->
    </b-row>
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1">
        <b-form-group label="Sort" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="sortBySelect"
          class="mb-0">
          <b-input-group size="sm">
            <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-75">
              <template v-slot:first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
          class="mb-0">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
          :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
          :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
          <template #cell(no)="row">
            <strong class="text-center">
              {{ row.index + 1 }}
            </strong>
          </template>
          <template #cell(nomor-resi)="row">
            <template v-if="row.item.shipping_orders && row.item.shipping_orders.length > 0">
              <b-badge v-for="(order, index) in row.item.shipping_orders" :key="index" variant="info" class="mr-1">
                {{ order.tracking_number }}
              </b-badge>
            </template>
            <template v-else>
              -
            </template>
          </template>
          <template #cell(created_at)="row">
            <strong class="text-center">
              {{ humanDateTime(row.value) }}
            </strong>
          </template>
          <template #cell(status)="row">
            <b-badge variant="info" class="mr-1">
              {{ row.value }}
            </b-badge>
          </template>
          <template #cell(actions)="row">
            <b-button v-b-tooltip.hover.right="'Ubah'" size="sm" @click="edit(row.item)" class="mr-1"
              variant="outline-info">
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button v-b-tooltip.hover.right="'Rute'" size="sm" @click="getGenerateRoute(row.item)" class="mr-1"
              variant="outline-info">
              <feather-icon icon="CompassIcon" />
            </b-button>
            <b-button v-b-tooltip.hover.right="'Hapus'" size="sm" @click="remove(row.item)" variant="outline-danger">
              <feather-icon icon="TrashIcon" />
            </b-button>
          </template>

          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
              </ul>
            </b-card>
          </template>
        </b-table>
      </b-col>
      <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
        <pre>{{ infoModal.content }}</pre>
      </b-modal>

      <b-col cols="12">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
          class="my-0" />
      </b-col>
    </b-row>
  </b-card-actions>
</template>


<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BTable, VBTooltip, BAvatar, BBadge, BModal, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton } from "bootstrap-vue";
import { mapGetters } from 'vuex';
import 'leaflet/dist/leaflet.css'
import LeafletBasic from './LeafletBasic.vue'

export default {
  components: {
    BCardActions,
    VBTooltip,
    ToastificationContent,
    BModal,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    vSelect,
    LeafletBasic,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      showModalTambah: false,
      showModalEdit: false,
      showModalDetail: false,
      showModalEditRule: false,
      showModalCreateRule: false,
      showModalOptimizeLog: false,
      showModalOptimizedMap: false,

      newRule: {
        price_per_km: '',
        minimal_distance: ''
      },
      id: null,
      driver_id: null,
      shipping_order_ids: [],
      driverOptions: [],
      shippingOrderOptions: [],
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      detailRoute: {
        best_route: [],
        generations: []
      },
      wpMap : [],
      fields: [
        { key: "no", label: "No" },
        { key: "driver.karyawan.nama_lengkap", label: "Nama Driver", sortable: true },
        { key: "nomor-resi", label: "Nomor Resi", sortable: true },
        { key: "created_at", label: "Tanggal", sortable: true },
        { key: "status", label: "Status", sortable: true },

        { key: "actions", label: "Aksi" },
      ],
      logFields: [
        { key: "no", label: "No" },
        { key: "generation", label: "Generasi" },
        { key: "logs", label: "Log" },
        { key: "best_individual.route_sequence", label: "Urutan Kunjungan" },
        { key: "best_individual.total_distance", label: "Nilai Jarak" },

      ],
      items: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }));
    },
    ...mapGetters({
      optimizedRouteData: 'shippingAssignment/getOpt', // Access optimize data from Vuex
    }),
  },
  mounted() {
    const params = { level_id: 18 };
    this.getData();
    this.getDriverOptions(params);
  },
  methods: {
    add() {
      this.id = null;
      this.fungsi = null;
      this.driver_id = null;
      this.shipping_order_ids = [];
      this.showModalTambah = true;
      this.showModalEdit = false;
      this.getShippingOrderOptions({ delivery_status: "Pending" }); // Filter by delivery_status: Pending for create modal
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data Tugas Pengiriman "${item.driver.karyawan.nama_lengkap}" ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          item.fungsi = 1; // soft delete
          this.$store
            .dispatch("shippingAssignment/save", item)
            .then(() => {
              this.pesanBerhasilHapus();
              this.resetForm();
              this.getData();
            })
            .catch((e) => {
              this.pesanGagalHapus();
              this.displayError(e);
              return false;
            });
        }
      });
    },
    resetForm() {
      this.id = null;
      this.driver_id = null;
      this.shipping_order_ids = [];
      this.showModalTambah = false;
      this.showModalEdit = false;
    },
    submit() {
      if (this.driver_id == null || this.shipping_order_ids.length == 0) {
        this.pesanGagalSimpan();
        return false;
      }
      let payload = {
        driver_id: this.driver_id,
        shipping_order_ids: this.shipping_order_ids,
      };

      if (this.id) {
        payload.id = this.id;
      }
      if (this.fungsi) {
        payload.fungsi = this.fungsi;
      }

      this.$store
        .dispatch("shippingAssignment/save", payload)
        .then(() => {
          this.resetForm();
          this.getData();
          this.pesanBerhasilSimpan();
        })
        .catch((e) => console.error(e));
    },
    edit(item) {
      console.log("item", item);

      let { id, driver_id, fungsi = 2 } = item;
      this.id = id;
      this.fungsi = fungsi;
      this.driver_id = driver_id;
      this.shipping_order_ids = item.shipping_orders.map(order => order.id);
      this.showModalEdit = true;
      this.getShippingOrderOptions();
    },

    showDetail(id) {
      this.$store
        .dispatch("shippingAssignment/getDataById", id)
        .then((response) => {
          this.detail = response;
          this.showModalDetail = true;
        })
        .catch((e) => {
          console.error(e);
          this.pesanGagalSimpan();
        });
    },
    showOptLog(response) {
        this.showModalOptimizeLog = true;
        this.detailRoute = response;
        
    },
    showOptMap(response) {
      const destination = response.map(order => 
          `${order.latitude},${order.longitude}`, // Assuming the order object contains `recipient_latitude`
      );
      this.wpMap = destination
      this.$bvModal.show('modal-route-opt')
    },

    generateRoute(item) {
      const courierTaskId = item.id; // Assuming courier_task_id is fixed for now
      const origin = [
        {
          courier_task_id: courierTaskId,
          name: 'Icommits',
          coordinates: {
            latitude: -6.9380896,
            longitude: 107.6592391
          }
        }
      ];
      const destination = item.shipping_orders.map(order => ({
        courier_task_id: courierTaskId,
        shipping_order_id: order.id, // Assuming the order object contains an `id`
        name: order.recipient_name, // Assuming the order object contains a `recipient_name`
        coordinates: {
          latitude: order.recipient_latitude, // Assuming the order object contains `recipient_latitude`
          longitude: order.recipient_longitude // Assuming the order object contains `recipient_longitude`
        }
      }));

      const payload = {
        origin,
        destination
      };

      // console.log("Generated Route Payload:", payload);
      this.$store
        .dispatch("shippingAssignment/getOptimizedById", payload)
        .then(() => {
          this.resetForm();
          // this.getData();
          this.pesanBerhasilGenerate();
        })
        .catch((e) => this.displayError(e));
    },
    getGenerateRoute(item) {
      console.log("item", item);

      const courierTaskId = item.id; // Assuming courier_task_id is fixed for now
      const origin = [
        {
          courier_task_id: courierTaskId,
          name: 'Icommits',
          coordinates: {
            latitude: -6.9380896,
            longitude: 107.6592391
          }
        }
      ];
      const destination = item.shipping_orders.map(order => ({
        courier_task_id: courierTaskId,
        shipping_order_id: order.id, // Assuming the order object contains an `id`
        name: order.recipient_name, // Assuming the order object contains a `recipient_name`
        coordinates: {
          latitude: order.recipient_latitude, // Assuming the order object contains `recipient_latitude`
          longitude: order.recipient_longitude // Assuming the order object contains `recipient_longitude`
        }
      }));

      const payload = {
        origin,
        destination
      };
      this.$store
        .dispatch("shippingAssignment/getOptimizedById", courierTaskId)
        .then((response) => {
          // console.log("response", response.length);
          if(response.length < 1) {
            this.$swal({
              title: "Generate Rute?",
              text: `Data Tugas Pengiriman "${item.id}" ini belum mempunyai rute `,
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Ya",
              cancelButtonText: "Batal",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                this.$store
                  .dispatch("shippingAssignment/generateOptimizedRoute", payload)
                  .then((response) => {
                    this.showOptLog(response);
                  })
                  .catch((e) => {
                    this.pesanGagalHapus();
                    this.displayError(e);
                    return false;
                  });
              }
            });
          }else{
            this.showOptMap(response)
            
          }
          // this.getData();
          // this.pesanBerhasilGenerate();
        })
        .catch((e) => {
          this.displayError(e)
        });
    },
    saveGenerateRoute() {
      let payload = this.detailRoute.best_solution;
      
      this.$store
        .dispatch('shippingAssignment/saveGenerateRoute',payload) // Payload berasal dari store
        .then(() => {
          this.pesanBerhasilSimpan()
          this.showModalOptimizeLog = false;

        })
        .catch(error => {
          console.error('Gagal menyimpan rute', error);
          this.displayError(error)
        });
    },
    getDriverOptions(params) {
      this.$store.dispatch("shippingAssignment/getDrivers", params).then(response => {
        this.driverOptions = response.map(driver => ({
          value: driver.karyawan.id,
          text: driver.karyawan.nama_lengkap
        }));
      }).catch(e => {
        console.error(e);
      });
    },
    getShippingOrderOptions(params) {
      this.$store.dispatch("shippingAssignment/getShippingOrders", params).then(response => {
        this.shippingOrderOptions = response.map(order => ({
          value: order.id,
          text: order.tracking_number + ' ' + order.recipient_name
        }));
      }).catch(e => {
        console.error(e);
      });
    },
    pesanBerhasilSimpan() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Data berhasil disimpan",
            icon: "CheckIcon",
            variant: "success",
          },
        },
        {
          position: "top-right",
          timeout: 5000,
        }
      );
    },
    pesanBerhasilGenerate() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Rute berhasil dibentuk",
            icon: "CheckIcon",
            variant: "success",
          },
        },
        {
          position: "top-right",
          timeout: 5000,
        }
      );
    },
    pesanGagalSimpan() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Data gagal disimpan",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        },
        {
          position: "top-right",
          timeout: 5000,
        }
      );
    },
    pesanBerhasilHapus() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Data berhasil dihapus",
            icon: "CheckIcon",
            variant: "success",
          },
        },
        {
          position: "top-right",
          timeout: 5000,
        }
      );
    },
    pesanGagalHapus() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Data gagal dihapus",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        },
        {
          position: "top-right",
          timeout: 5000,
        }
      );
    },
    getData() {
      this.$store
        .dispatch("shippingAssignment/getData")
        .then((response) => {
          this.items = response;
          this.totalRows = this.items.length;
        })
        .catch((e) => console.error(e));
    },
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/map-leaflet.scss';
</style>
