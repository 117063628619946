<template>
  <b-modal id="modal-route-opt" size="xl" cancel-variant="secondary" ok-only @shown="modalShown"
    @hidden="$emit('clear')" ok-title="Close" centered title="x Paket Pengiriman ">
    <l-map :zoom="zoom" :center="center" style="height: 500px;" ref="map" @ready="initializeRouting">
      <l-tile-layer :url="url" />
    </l-map>
  </b-modal>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { LMap, LTileLayer } from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import 'leaflet-routing-machine'
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css'

// Hapus icon default dan tambahkan icon Leaflet
import { Icon } from 'leaflet'
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

export default {
  components: {
    LMap,
    LTileLayer,
    BCardCode,
  },
  props: {
    waypoints: {
      type: Array,
      required: true,  // This ensures the waypoints prop is passed and is an array
    }
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 8,
      center: [-6.9380896, 107.6592391], // Center map pada koordinat ini
      waypoints: [],
      mapboxToken: process.env.VUE_APP_API_MAPBOX
    }
  },
  methods: {
    modalShown() {
      setTimeout(() => {
        const map = this.$refs.map.mapObject;
        map.invalidateSize(); // Recalculate map size
        // Fit the map to the bounds of all the waypoints
        const bounds = L.latLngBounds(this.waypoints.map(coord => L.latLng(...coord.split(','))));
        map.fitBounds(bounds); // Adjust zoom and center dynamically based on bounds
        
        // After fitting bounds, zoom out by 1 level
        // map.setZoom(map.getZoom() - 1);
      }, 300); // Small delay to ensure modal is fully shown
    },
    initializeRouting() {
      const map = this.$refs.map.mapObject; // Referensi ke objek peta
      
      // Routing control dengan custom marker dengan angka dinamis
      L.Routing.control({
        waypoints: this.waypoints.map(coord => L.latLng(...coord.split(','))),  // Convert wpMap strings into L.latLng,
        routeWhileDragging: true,
        router: L.Routing.mapbox(this.mapboxToken),
        createMarker: (i, wp) => {
          // Cek apakah marker adalah waypoint pertama atau terakhir
          if (i === 0 || i === this.waypoints.length - 1) {
            // Buat marker dengan ikon rumah untuk waypoint pertama dan terakhir
            return L.marker(wp.latLng, {
              icon: L.icon({
                iconUrl: '/assets/logo-balanja.png', // Gambar ikon rumah
                iconSize: [32, 37], // Ukuran ikon rumah
                iconAnchor: [16, 37], // Anchor dari ikon rumah
              })
            }).bindPopup(`Waypoint ${i}`).openPopup();
          } else {
            // Buat marker dengan DivIcon yang berisi angka dinamis untuk waypoint di tengah
            const divIcon = L.divIcon({
              className: 'custom-div-icon', // Class CSS untuk styling
              html: `<div class="custom-marker">${i}</div>`, // Angka dinamis
              iconSize: [32, 37], // Ukuran ikon yang disesuaikan dengan icon default
              iconAnchor: [16, 37], // Anchor dari marker yang sesuai dengan icon default
            });

            // Tambahkan marker ke map dengan ikon div
            return L.marker(wp.latLng, {
              icon: divIcon // Gunakan DivIcon dengan angka dinamis
            }).bindPopup(`Waypoint ${i + 1}`).openPopup();
          }
        },
      }).addTo(map);
    },
  },
}
</script>

<style>
.vue2leaflet-map {
  &.leaflet-container {
    height: 500px;
  }
}

/* Styling untuk DivIcon agar menampilkan angka dengan background marker default dan lingkaran */
.custom-div-icon {
  background-image: url('/assets/marker.png'); /* Jalur ke gambar marker */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 32px; /* Disesuaikan dengan ukuran marker default */
  height: 37px; /* Disesuaikan dengan ukuran marker default */
  position: relative;
  text-align: center;
  color: white;
  font-size: 14px;
  line-height: 37px; /* Sesuaikan line-height dengan tinggi marker */
}

/* Lingkaran latar belakang untuk angka */
.custom-marker {
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 22px; /* Sesuaikan ukuran lingkaran */
  height: 22px; /* Sesuaikan ukuran lingkaran */
  background-color: #559aff; /* Warna lingkaran */
  border-radius: 50%; /* Membuat lingkaran */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: white;
}
</style>
